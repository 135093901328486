import React, {Component} from 'react';

export default class MetaTreePage extends Component {

  render() {
    return (

      <div>
        MetaTreePage
      </div>

    );
  }
}
