// @flow weak

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Flask(props) {
  return <SvgIcon {...props} viewBox="0 0 520 520">
    <path d="M454.339,398.413c-0.168-0.281-0.346-0.555-0.532-0.824l-60.589-87.461c-0.153-0.235-0.312-0.465-0.479-0.692
			l-61.741-89.124V87.43c17.461-6.191,30-22.873,30-42.43c0-24.813-20.187-45-45-45h-120c-24.813,0-45,20.187-45,45
			c0,19.556,12.539,36.239,30,42.43v132.882L58.189,397.588c-0.187,0.269-0.364,0.544-0.532,0.824
			C27.655,448.417,63.619,512,121.97,512h268.056C448.339,512,484.361,448.45,454.339,398.413z M203.854,240h22.144
			c8.284,0,15-6.716,15-15s-6.716-15-15-15h-15v-30h15c8.284,0,15-6.716,15-15s-6.716-15-15-15h-15v-30h15c8.284,0,15-6.716,15-15
			s-6.716-15-15-15h-15V75c0-8.284-6.716-15-15-15c-8.271,0-15-6.729-15-15s6.729-15,15-15h120c8.271,0,15,6.729,15,15
			s-6.729,15-15,15c-8.284,0-15,6.716-15,15v150c0,3.052,0.932,6.033,2.67,8.542l34.813,50.254
			c-22.696-5.101-46.859-2.378-67.983,8.184l-36.184,18.092c-22.971,11.485-50.512,9.77-71.879-4.475
			c-0.917-0.612-1.849-1.203-2.786-1.785L203.854,240z M390.025,482H121.97c-34.853,0-56.401-37.808-38.821-67.76l59.387-85.726
			c1.103,0.653,2.192,1.332,3.258,2.043c30.186,20.124,69.238,22.696,101.937,6.347l36.184-18.092
			c20.09-10.045,44.078-10.045,64.168,0l22.425,11.212l58.341,84.216C446.419,444.18,424.893,482,390.025,482z"/>
  </SvgIcon>;
}
